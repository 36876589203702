import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import HomeNew from '../views/HomeNew.vue'

import store from "../store";

Vue.use(VueRouter)

const requireAuthenticated = (to, from, next) => {
  store.dispatch("auth/initialize").then(() => {
    if (!store.getters["auth/isAuthenticated"]) {
      next("/login");
    } else {
      next();
    }
  });
};

const requireUnauthenticated = (to, from, next) => {
  store.dispatch("auth/initialize").then(() => {
    if (store.getters["auth/isAuthenticated"]) {
      next("/");
    } else {
      next();
    }
  });
};

const redirectLogout = (to, from, next) => {
  store.dispatch("auth/logout").then(() => next("/"));
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeNew
  },
  {
    path: '/about',
    name: 'O nas',
    component: Home
  },
  {
    path: "/login",
    name: "Prihlásenie",
    component: () => import('../views/Login.vue'),
    beforeEnter: requireUnauthenticated
  },
  {
    path: "/logout",
    beforeEnter: redirectLogout
  },
  {
    path: "/register",
    name: "Registrácia",
    component: () => import('../views/Register.vue'),
    beforeEnter: requireUnauthenticated
  },
  {
    path: "/register_verify",
    name: "Verifikácia 1",
    component: () => import('../views/RegisterVerify.vue'),
    beforeEnter: requireAuthenticated
  },
  {
    path: "/bank_verify",
    name: "Verifikácia 2",
    component: () => import('../views/BankVerify.vue'),
    beforeEnter: requireAuthenticated
  },

  {
    path: "/profile/:tabID",
    name: "Profil",
    component: () => import('../views/Profile.vue'),
    beforeEnter: requireAuthenticated
  },
  {
    path: "/candidate",
    name: "Kandidát prihláška",
    component: () => import('../views/Candidate.vue'),
    beforeEnter: requireAuthenticated
  },
  {
    path: "/member",
    name: "Člen prihláška",
    component: () => import('../views/MemberApplication.vue'),
    beforeEnter: requireAuthenticated
  },

  {
    path: "/list",
    name: "Zoznam",
    component: () => import('../views/List.vue'),
  },
  {
    path: "/list_table",
    name: "Zoznam tabuľka",
    component: () => import('../views/ListTable.vue'),
  },
  {
    path: "/admin/:tabID",
    name: "admin",
    component: () => import('../views/Admin.vue'),
  },
  {
    path: "/moderator",
    name: "moderator",
    component: () => import('../views/moderator/Moderator.vue'),
  },

  {
    path: "/admin/menu",
    name: "admin_menu",
    component: () => import('../views/admin/Menu.vue'),
  },
  {
    path: "/admin/pages",
    name: "admin_pages",
    component: () => import('../views/admin/Pages.vue'),
  },
  {
    path: '/page/:id',
    name: "page",
    component: () => import('../views/Page.vue'),
  },
  {
    path: '/members',
    name: "Zoznam členov",
    component: () => import('../views/MembersList.vue'),
  },
  {
    path: '/referendum',
    name: "Referendá",
    component: () => import('../views/Referendum.vue'),
  },
  {
    path: '/question_choose',
    name: "Výbery otázok",
    component: () => import('../views/QuestionChoose.vue'),
  },
  {
    path: '/survey/:surveyID',
    name: "Prieskum",
    component: () => import('../views/Survey.vue'),
  },

  {
    path: '/survey_discussion/:surveyID/:partyID',
    name: "Diskusia prieskumy",
    component: () => import('../views/SurveyDiscussion.vue'),
  },


  {
    path: '/referendum/:referendumID/question/:questionID/discussion',
    name: "Diskusia k referendum",
    component: () => import('../views/ReferendumDiscussion.vue'),
  },

  {
    path: '/poll_category/:categoryID/',
    name: "Kategoria ankety",
    component: () => import('../views/poll/PollCategory.vue'),
  },

  {
    path: '/poll_category/:categoryID/poll/:pollID',
    name: "Anketa",
    component: () => import('../views/poll/Poll.vue'),
  },

  {
    path: '/poll_question/:questionID',
    name: "Otazka",
    component: () => import('../views/poll/PollQuestion.vue'),
  },

  {
    path: '/poll_category/:categoryID/poll_sub_questions/:questionID',
    name: "Otazka",
    component: () => import('../views/poll/PollSubQuestions.vue'),
  },

  {
    path: '/poll_question_discussion/:questionID',
    name: "Diskusia general",
    component: () => import('../views/poll/PollDiscussion.vue'),
  },


  {
    path: '/password_reset',
    name: "Zabudnuté heslo",
    component: () => import('../views/LostPassword.vue'),
  },

  {
    path: '/password_reset_final',
    name: "Zabudnuté heslo final",
    component: () => import('../views/LostPasswordFinal.vue'),
  },

  {
    path: '/logo',
    name: "Logo",
    component: () => import('../views/Logo.vue'),
  },

  {
    path: '/clocks',
    name: "Clocks",
    component: () => import('../views/ClocksPage.vue'),
  },
  {
    path: '/news',
    name: "News",
    component: () => import('../views/News.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
