import session from '@/api/session';


const initialState = {
    menu: [],
    menuPoll: [],
    adminNewQuestions: 0
};

const getters = {
    getMenu: state => state.menu,
    getMenuPoll: state => state.menuPoll,
    adminNewQuestions: state => state.adminNewQuestions
};

const actions = {
    fetchMenu({ commit }) {
        const url = `/page/`;
        session.get(url, {}).then(response => {
            commit("setMenu", response.data.results);
        });
    },
    fetchMenuPoll({ commit }) {
        const url = `/general_poll_category_menu/`;
        session.get(url, {}).then(response => {
            commit("setMenuPoll", response.data);
        });
    },

    fetchAdminNewQuestions({ commit }) {
        const url = `/admin_news/`;
        session.get(url, {}).then(response => {
            commit("setAdminNewQuestions", response.data);
        }).catch(() => { });
    }
};

const mutations = {
    setMenu(state, payload) {
        state.menu = payload;
    },
    setMenuPoll(state, payload) {
        state.menuPoll = payload;
    },

    setAdminNewQuestions(state, payload) {
        state.adminNewQuestions = payload;
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
