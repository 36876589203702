import axios from 'axios';
import router from '@/router'
import store from '@/store'

const CSRF_COOKIE_NAME = 'csrftoken';
const CSRF_HEADER_NAME = 'X-CSRFToken';

const session = axios.create({
  // baseURL: 'http://localhost:8000',
  baseURL: 'https://hnutieb.jur1cek.eu/',

  // baseURL: 'https://api.nasehnutie.sk/',
  xsrfCookieName: CSRF_COOKIE_NAME,
  xsrfHeaderName: CSRF_HEADER_NAME,
});

session.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 500 || error.response.status === 404) {
    return Promise.reject(error);
  }

  if (error.response.status === 403) {
    return;
  }

  store.commit('notifications/raiseError', error.response.data);

  if (error.response.status === 401) {
    store.dispatch('auth/logout').then(() => router.push('/login'));
  }

  return Promise.reject(error);
})


export default session;