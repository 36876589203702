<template>
  <div>
    <v-row
      justify="space-around"
      class="ma-10"
    >
      <v-col
        cols="12"
        md="5"
        sm="12"
      >
        <v-img
          class="blob"
          src="@/assets/mapa.png"
        ></v-img>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card v-if="staticPages.length > 0">
          <v-card-title
            style="word-break: break-word;"
            class="justify-center"
          > {{ staticPages[0].title }} </v-card-title>

          <v-card-subtitle> {{ staticPages[0].subtitle }} </v-card-subtitle>
          <v-card-text>
            <span v-html="staticPages[0].content"></span>
          </v-card-text>
          <v-card-actions></v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <v-card
          class="overflow-y-auto"
          :height="getHeight()"
          v-if="staticPages.length > 0"
        >
          <v-card-title
            style="word-break: break-word;"
            class="justify-center"
          > {{ staticPages[1].title }} </v-card-title>
          <v-card-subtitle> {{ staticPages[1].subtitle }} </v-card-subtitle>
          <v-card-text>
            <span v-html="staticPages[1].content"></span>
          </v-card-text>
          <v-card-actions></v-card-actions>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-card
          class="overflow-y-auto"
          :height="getHeight()"
          v-if="staticPages.length > 0"
        >
          <v-card-title
            style="word-break: break-word;"
            class="justify-center"
          > {{ staticPages[2].title }} </v-card-title>
          <v-card-subtitle> {{ staticPages[2].subtitle }} </v-card-subtitle>
          <v-card-text>
            <span v-html="staticPages[2].content"></span>
          </v-card-text>
          <v-card-actions></v-card-actions>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <v-card
          class="overflow-y-auto"
          v-if="staticPages.length > 0"
          :height="getHeight()"
          ref="card4"
        >
          <v-card-title
            style="word-break: break-word;"
            class="justify-center"
          > {{ staticPages[3].title }} </v-card-title>
          <v-card-subtitle> {{ staticPages[3].subtitle }} </v-card-subtitle>
          <v-card-text>
            <span v-html="staticPages[3].content"></span>
          </v-card-text>
          <v-card-actions></v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import { APIService } from "@/http/APIService.js";

const apiService = new APIService();
export default {
  name: "Home",

  data() {
    return {
      staticPages: [],
      height: "auto"
    }
  },

  mounted: function () {
    this.getStaticPages();
  },


  computed: {
    ...mapGetters("auth", ["isAuthenticated", "username"]),
    ...mapGetters("settings", ["getSettings"]),
  },

  methods: {
    getHeight() {
      return this.getSettings.homepage_vyska + "px";
    },

    getStaticPages() {
      apiService.getStaticPages().then((response) => {
        this.staticPages = response.results;
      });
    },
  },
};
</script>

<style scoped>
.blob {
  animation: pulse 1.1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  15% {
    transform: scale(1.15);
  }

  30% {
    transform: scale(1);
  }

  40% {
    transform: scale(1.1);
  }

  80% {
    transform: scale(1);
  }
}
</style>