import session from '@/api/session';

export class APIService {
  constructor() { }

  buildFilterUrl(total, value) {
    return total + `&${value[0]}=${value[1]}`;
  }

  buildSortUrl(total, value,) {
    return total + `${value},`;
  }

  getAutoComplete(model, value) {
    let url = "";

    url = `/autocomplete/?model=${model}&search=${value}`;

    return session.get(url, {}).then(response => response.data);
  }

  adminNewQuestions(model, value) {
    let url = "";

    url = `/admin_news/`;

    return session.get(url, {}).then(response => response.data);
  }

  register(item) {
    const url = `/register/`;
    return session.post(url, item).then(response => response.data);
  }

  patchAdminUser(item) {
    const url = `/adminuser/${item.id}/`;
    return session.patch(url, item).then(response => response.data);
  }

  resendAdminUserSMS(item) {
    const url = `/adminuser/${item.id}/resend_verification_sms/`;
    return session.post(url, {}).then(response => response.data);
  }

  resendAdminUserEmail(item) {
    const url = `/adminuser/${item.id}/resend_verification_email/`;
    return session.post(url, {}).then(response => response.data);
  }

  updateUser(item) {
    const url = `/user/${item.id}/`;
    return session.put(url, item).then(response => response.data);
  }

  patchUser(item, id) {
    const url = `/user/${id}/`;
    return session.patch(url, item).then(response => response.data);
  }

  adminDeleteUser(item) {
    const url = `/user/${item.id}/`;
    return session.delete(url, {}).then(response => response.data);
  }

  adminVerifyUser(item) {
    const url = `/user/${item.id}/admin_verify/`;
    return session.post(url, {}).then(response => response.data);
  }

  searchUsers(search) {
    const url = `/user/?search=${search}`;

    return session.get(url, {}).then(response => response.data);
  }

  getAdminUsers(sort, search, limit, offset, filter) {
    let url = "";

    if (filter) {
      var filter_url = filter.reduce(this.buildFilterUrl, "");

      url = `/adminuser/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}${filter_url}`;
    }
    else
      url = `/adminuser/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}`;

    return session.get(url, {}).then(response => response.data);
  }

  getMe() {
    const url = `/user/me/`;
    return session.get(url).then(response => response.data);
  }


  verifySMS(item) {
    const url = `/user/verify_sms/`;
    return session.post(url, item).then(response => response.data);
  }

  verifyEmail(item) {
    const url = `/user/verify_email/`;
    return session.post(url, item).then(response => response.data);
  }

  getBankTransfers(sort, search, limit, offset, filter) {

    let url = "";

    if (filter) {
      var filter_url = filter.reduce(this.buildFilterUrl, "");

      url = `/bank_transfer/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}${filter_url}`;
    }
    else
      url = `/bank_transfer/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}`;

    return session.get(url, {}).then(response => response.data);
  }

  updateBankTransfer(item) {
    const url = `/bank_transfer/${item.id}/`;
    return session.put(url, item).then(response => response.data);
  }

  createCandidate(item) {
    const url = `/candidate/`;
    return session.post(url, item).then(response => response.data);
  }

  updateCandidate(item, id) {
    const url = `/candidate/${id}/`;
    return session.put(url, item).then(response => response.data);
  }

  createUserCandidate(item) {
    const url = `/candidate_user/`;
    return session.post(url, item).then(response => response.data);
  }

  updateUserCandidate(item, id) {
    const url = `/candidate_user/${id}/`;
    return session.put(url, item).then(response => response.data);
  }

  getUserCandidate(id) {
    const url = `/candidate_user/${id}/`;
    return session.get(url, {}).then(response => response.data);
  }


  getKandidats(sort, search, limit, offset, filter) {
    let url = "";

    if (filter) {
      var filter_url = filter.reduce(this.buildFilterUrl, "");

      url = `/candidate/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}${filter_url}`;
    }
    else
      url = `/candidate/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}`;

    return session.get(url, {}).then(response => response.data);
  }

  getKandidat(id) {
    const url = `/candidate/${id}/`;
    return session.get(url, {}).then(response => response.data);
  }

  voteKandidat(id) {
    const url = `/candidate/${id}/vote/`;
    return session.post(url, {}).then(response => response.data);
  }

  unvoteKandidat(id) {
    const url = `/candidate/${id}/unvote/`;
    return session.post(url, {}).then(response => response.data);
  }

  addFavoriteKandidat(id) {
    const url = `/candidate/${id}/add_favorite/`;
    return session.post(url, {}).then(response => response.data);
  }

  removeFavoriteKandidat(id) {
    const url = `/candidate/${id}/remove_favorite/`;
    return session.post(url, {}).then(response => response.data);
  }

  createMember(item) {
    const url = `/adminmember/`;
    return session.post(url, item).then(response => response.data);
  }

  updateMember(item) {
    const url = `/adminmember/${item.id}/`;
    return session.put(url, item).then(response => response.data);
  }

  getMembers(sort, search, limit, offset, filter) {

    let url = "";

    if (filter) {
      var filter_url = filter.reduce(this.buildFilterUrl, "");

      url = `/adminmember/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}${filter_url}`;
    }
    else
      url = `/adminmember/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}`;

    return session.get(url, {}).then(response => response.data);
  }

  getPublicMembers(sort, search, limit, offset, filter) {
    let url = "";

    if (filter) {
      var filter_url = filter.reduce(this.buildFilterUrl, "");

      url = `/member/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}${filter_url}`;
    }
    else
      url = `/member/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}`;

    return session.get(url, {}).then(response => response.data);
  }

  getMember(id) {
    const url = `/adminmember/${id}/`;
    return session.get(url, {}).then(response => response.data);
  }


  createStaff(item) {
    const url = `/staff/`;
    return session.post(url, item).then(response => response.data);
  }

  getStaffs(sort, search, limit, offset, filter) {
    let url = "";

    if (filter) {
      var filter_url = filter.reduce(this.buildFilterUrl, "");

      url = `/staff/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}${filter_url}`;
    }
    else
      url = `/staff/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}`;

    return session.get(url, {}).then(response => response.data);
  }

  getStaff(id) {
    const url = `/staff/${id}/`;
    return session.get(url, {}).then(response => response.data);
  }

  getDatatableProperties(uniqueID) {
    const url = `/datatableproperties/?data_table_id=${uniqueID}`;
    return session.get(url, {}).then(response => response.data.results[0]);
  }

  createDatatableProperties(item) {
    const url = `/datatableproperties/`;
    return session.post(url, item).then(response => response.data);
  }

  updateDatatableProperties(item) {
    const url = `/datatableproperties/${item.id}/`;
    return session.put(url, item).then(response => response.data);
  }

  getStatistics() {
    const url = `/statistics/`;
    return session.get(url, {}).then(response => response.data.results[0]);
  }

  // Admin
  getClocksPage() {
    const url = `/clockspage/1/`;
    return session.get(url, {}).then(response => response.data);
  }

  updateClocksPage(item) {
    const url = `/clockspage/${item.id}/`;
    return session.put(url, item).then(response => response.data);
  }
  
  getAdminSettings(sort, search, limit, offset, filter) {
    const url = `/adminsettings/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}`;
    return session.get(url, {}).then(response => response.data);
  }

  getAdminSetting(id) {
    const url = `/adminsettings/${id}/`;
    return session.get(url, {}).then(response => response.data);
  }

  updateAdminSetting(item) {
    const url = `/adminsettings/${item.id}/`;
    return session.put(url, item).then(response => response.data);
  }

  getAdminKandidats(sort, search, limit, offset, filter) {
    let url = "";

    if (filter) {
      var filter_url = filter.reduce(this.buildFilterUrl, "");

      url = `/admincandidate/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}${filter_url}`;
    }
    else
      url = `/admincandidate/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}`;

    return session.get(url, {}).then(response => response.data);
  }

  getAdminKandidat(id) {
    const url = `/admincandidate/${id}/`;
    return session.get(url, {}).then(response => response.data);
  }

  deleteAdminKandidat(item) {
    const url = `/admincandidate/${item.id}/`;
    return session.delete(url, {}).then(response => response.data);
  }

  updateAdminKandidat(item) {
    const url = `/admincandidate/${item.id}/`;
    return session.put(url, item).then(response => response.data);
  }

  approveAdminKandidat(item) {
    const url = `/admincandidate/${item.id}/approve/`;
    return session.post(url, {}).then(response => response.data);
  }

  disapproveAdminKandidat(item) {
    const url = `/admincandidate/${item.id}/disapprove/`;
    return session.post(url, {}).then(response => response.data);
  }

  approveMember(item) {
    const url = `/adminmember/${item.id}/approve/`;
    return session.post(url, {}).then(response => response.data);
  }

  disapproveMember(item) {
    const url = `/adminmember/${item.id}/disapprove/`;
    return session.post(url, {}).then(response => response.data);
  }

  approveStaff(item) {
    const url = `/staff/${item.id}/approve/`;
    return session.post(url, {}).then(response => response.data);
  }

  disapproveStaff(item) {
    const url = `/staff/${item.id}/disapprove/`;
    return session.post(url, {}).then(response => response.data);
  }

  getPages(sort, search, limit, offset) {
    const url = `/page/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}`;
    return session.get(url, {}).then(response => response.data);
  }

  getFlatPages(sort, search, limit, offset) {
    const url = `/pageflat/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}`;
    return session.get(url, {}).then(response => response.data);
  }

  getFlatPage(id) {
    const url = `/pageflat/${id}/`;
    return session.get(url, {}).then(response => response.data);
  }


  getPage(id) {
    const url = `/page/${id}/`;
    return session.get(url, {}).then(response => response.data);
  }

  getAdminPages(sort, search, limit, offset) {
    const url = `/adminpage/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}`;
    return session.get(url, {}).then(response => response.data);
  }

  getAdminPage(id) {
    const url = `/adminpage/${id}/`;
    return session.get(url, {}).then(response => response.data);
  }

  createAdminPage(item) {
    const url = `/adminpage/`;
    return session.post(url, item).then(response => response.data);
  }

  updateAdminPage(item) {
    const url = `/adminpage/${item.id}/`;
    return session.put(url, item).then(response => response.data);
  }

  deleteAdminPage(item) {
    const url = `/adminpage/${item.id}/`;
    return session.delete(url, {}).then(response => response.data);
  }

  getStaticPages(sort, search, limit, offset) {
    const url = `/pagestatic/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}`;
    return session.get(url, {}).then(response => response.data);
  }

  updateStaticPage(item) {
    const url = `/pagestatic/${item.id}/`;
    return session.put(url, item).then(response => response.data);
  }

  getAdminFlatPages(sort, search, limit, offset) {
    const url = `/adminpageflat/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}`;
    return session.get(url, {}).then(response => response.data);
  }

  getAdminFlatPage(id) {
    const url = `/adminpageflat/${id}/`;
    return session.get(url, {}).then(response => response.data);
  }

  createAdminFlatPage(item) {
    const url = `/adminpageflat/`;
    return session.post(url, item).then(response => response.data);
  }

  updateAdminFlatPage(item) {
    const url = `/adminpageflat/${item.id}/`;
    return session.put(url, item).then(response => response.data);
  }

  deleteAdminFlatPage(item) {
    const url = `/adminpageflat/${item.id}/`;
    return session.delete(url, {}).then(response => response.data);
  }

  getFiles(sort, search, limit, offset) {
    const url = `/fileupload/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}`;
    return session.get(url, {}).then(response => response.data);
  }

  getFile(id) {
    const url = `/fileupload/${id}/`;
    return session.get(url, {}).then(response => response.data);
  }

  createFile(item) {
    const url = `/fileupload/`;
    return session.post(url, item).then(response => response.data);
  }

  updateFile(item) {
    const url = `/fileupload/${item.id}/`;
    return session.put(url, item).then(response => response.data);
  }

  deleteFile(item) {
    const url = `/fileupload/${item.id}/`;
    return session.delete(url, {}).then(response => response.data);
  }


  searchObec(query) {
    let url = "";

    url = `/obec/?search=${query}`;

    return session.get(url, {}).then(response => response.data.results);
  }


  createReferendumQuestion(item) {
    const url = `/referendum_question/`;
    return session.post(url, item).then(response => response.data);
  }


  getGeneralPollCategoriesAdmin(sort, search, limit, offset, filter) {
    let url = "";
    let sort_merged = "";

    if (sort) {
      sort_merged = sort.reduce(this.buildSortUrl, "")
    }

    if (filter) {
      var filter_url = filter.reduce(this.buildFilterUrl, "");

      url = `/general_poll_category_admin/?search=${search}&ordering=${sort_merged}&limit=${limit}&offset=${offset}${filter_url}`;
    }
    else
      url = `/general_poll_category_admin/?search=${search}&ordering=${sort_merged}&limit=${limit}&offset=${offset}`;

    return session.get(url, {}).then(response => response.data);
  }

  getGeneralPollCategoriesModerator(sort, search, limit, offset, filter) {
    let url = "";
    let sort_merged = "";

    if (sort) {
      sort_merged = sort.reduce(this.buildSortUrl, "")
    }

    if (filter) {
      var filter_url = filter.reduce(this.buildFilterUrl, "");

      url = `/general_poll_category_moderator/?search=${search}&ordering=${sort_merged}&limit=${limit}&offset=${offset}${filter_url}`;
    }
    else
      url = `/general_poll_category_moderator/?search=${search}&ordering=${sort_merged}&limit=${limit}&offset=${offset}`;

    return session.get(url, {}).then(response => response.data);
  }


  getGeneralPollCategoryAdmin(id) {
    const url = `/general_poll_category_admin/${id}/`;
    return session.get(url).then(response => response.data);
  }

  createGeneralPollCategoryAdmin(item) {
    const url = `/general_poll_category_admin/`;
    return session.post(url, item).then(response => response.data);
  }

  updateGeneralPollCategoryAdmin(item) {
    const url = `/general_poll_category_admin/${item.id}/`;
    return session.put(url, item).then(response => response.data);
  }

  deleteGeneralPollCategoryAdmin(id) {
    const url = `/general_poll_category_admin/${id}`;
    return session.delete(url, {}).then(response => response.data);
  }

  getGeneralPollAdmin(id) {
    const url = `/general_poll_admin/${id}/`;
    return session.get(url).then(response => response.data);
  }

  getGeneralPollsAdmin(sort, search, limit, offset, filter) {
    let url = "";
    let sort_merged = "";

    if (sort) {
      sort_merged = sort.reduce(this.buildSortUrl, "")
    }

    if (filter) {
      var filter_url = filter.reduce(this.buildFilterUrl, "");

      url = `/general_poll_admin/?search=${search}&ordering=${sort_merged}&limit=${limit}&offset=${offset}${filter_url}`;
    }
    else
      url = `/general_poll_admin/?search=${search}&ordering=${sort_merged}&limit=${limit}&offset=${offset}`;

    return session.get(url, {}).then(response => response.data);
  }


  createGeneralPollAdmin(item) {
    const url = `/general_poll_admin/`;
    return session.post(url, item).then(response => response.data);
  }

  updateGeneralPollAdmin(item) {
    const url = `/general_poll_admin/${item.id}/`;
    return session.put(url, item).then(response => response.data);
  }

  deleteGeneralPollAdmin(id) {
    const url = `/general_poll_admin/${id}`;
    return session.delete(url, {}).then(response => response.data);
  }

  getGeneralPollQuestion(id) {
    const url = `/general_poll_question/${id}/`;
    return session.get(url).then(response => response.data);
  }

  getGeneralPollQuestionAdmin(id) {
    const url = `/general_poll_question_admin/${id}/`;
    return session.get(url).then(response => response.data);
  }

  createGeneralPollQuestionAdmin(item) {
    const url = `/general_poll_question_admin/`;
    return session.post(url, item).then(response => response.data);
  }

  patchGeneralPollQuestionAdmin(item, id) {
    const url = `/general_poll_question_admin/${id}/`;
    return session.patch(url, item).then(response => response.data);
  }

  updateGeneralPollQuestionAdmin(item, id) {
    const url = `/general_poll_question_admin/${id}/`;
    return session.put(url, item).then(response => response.data);
  }

  deleteGeneralPollQuestionAdmin(id) {
    const url = `/general_poll_question_admin/${id}`;
    return session.delete(url, {}).then(response => response.data);
  }


  getGeneralPollQuestionComments(sort, search, limit, offset, filter) {
    let url = "";

    if (filter) {
      var filter_url = filter.reduce(this.buildFilterUrl, "");

      url = `/general_poll_question_comment/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}${filter_url}`;
    }
    else
      url = `/general_poll_question_comment/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}`;

    return session.get(url, {}).then(response => response.data);
  }

  getReferendumQuestionComments(sort, search, limit, offset, filter) {
    let url = "";

    if (filter) {
      var filter_url = filter.reduce(this.buildFilterUrl, "");

      url = `/referendum_question_comment/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}${filter_url}`;
    }
    else
      url = `/referendum_question_comment/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}`;

    return session.get(url, {}).then(response => response.data);
  }

  getGeneralPollQuestions(sort, search, limit, offset, filter) {
    let url = "";
    let sort_merged = "";

    if (sort) {
      sort_merged = sort.reduce(this.buildSortUrl, "")
    }

    if (filter) {
      var filter_url = filter.reduce(this.buildFilterUrl, "");

      url = `/general_poll_question/?search=${search}&ordering=${sort_merged}&limit=${limit}&offset=${offset}${filter_url}`;
    }
    else
      url = `/general_poll_question/?search=${search}&ordering=${sort_merged}&limit=${limit}&offset=${offset}`;

    return session.get(url, {}).then(response => response.data);
  }

  generalPollQuestionVote(id, type) {
    const url = `/general_poll_question/${id}/vote/`;
    return session.post(url, { "type": type }).then(response => response.data);
  }

  generalPollQuestionUnVote(id) {
    const url = `/general_poll_question/${id}/unvote/`;
    return session.post(url, {}).then(response => response.data);
  }


  generalPollQuestionAddFavorite(id) {
    const url = `/general_poll_question/${id}/add_favorite/`;
    return session.post(url).then(response => response.data);
  }

  generalPollQuestionRemoveFavorite(id) {
    const url = `/general_poll_question/${id}/remove_favorite/`;
    return session.post(url).then(response => response.data);
  }

  getGeneralPollCategories(sort, search, limit, offset, filter) {
    let url = "";
    let sort_merged = "";

    if (sort) {
      sort_merged = sort.reduce(this.buildSortUrl, "")
    }

    if (filter) {
      var filter_url = filter.reduce(this.buildFilterUrl, "");

      url = `/general_poll_category/?search=${search}&ordering=${sort_merged}&limit=${limit}&offset=${offset}${filter_url}`;
    }
    else
      url = `/general_poll_category/?search=${search}&ordering=${sort_merged}&limit=${limit}&offset=${offset}`;

    return session.get(url, {}).then(response => response.data);
  }


  getGeneralPollCategory(id) {
    const url = `/general_poll_category/${id}/`;
    return session.get(url).then(response => response.data);
  }

  generalPollCategoryAddFavorite(id) {
    const url = `/general_poll_category/${id}/add_favorite/`;
    return session.post(url).then(response => response.data);
  }

  generalPollCategoryRemoveFavorite(id) {
    const url = `/general_poll_category/${id}/remove_favorite/`;
    return session.post(url).then(response => response.data);
  }



  getGeneralPoll(id) {
    const url = `/general_poll/${id}/`;
    return session.get(url).then(response => response.data);
  }

  getGeneralPolls(sort, search, limit, offset, filter) {
    let url = "";
    let sort_merged = "";

    if (sort) {
      sort_merged = sort.reduce(this.buildSortUrl, "")
    }

    if (filter) {
      var filter_url = filter.reduce(this.buildFilterUrl, "");

      url = `/general_poll/?search=${search}&ordering=${sort_merged}&limit=${limit}&offset=${offset}${filter_url}`;
    }
    else
      url = `/general_poll/?search=${search}&ordering=${sort_merged}&limit=${limit}&offset=${offset}`;

    return session.get(url, {}).then(response => response.data);
  }

  generalPollAddFavorite(id) {
    const url = `/general_poll/${id}/add_favorite/`;
    return session.post(url).then(response => response.data);
  }

  generalPollRemoveFavorite(id) {
    const url = `/general_poll/${id}/remove_favorite/`;
    return session.post(url).then(response => response.data);
  }


  generalPollQuestionCommentRead(id) {
    const url = `/general_poll_question_comment/${id}/read/`;

    return session.post(url, {}).then(response => response.data);
  }

  createGeneralPollQuestionComment(item) {
    const url = `/general_poll_question_comment/`;
    return session.post(url, item).then(response => response.data);
  }

  updateGeneralPollQuestionComment(item) {
    const url = `/general_poll_question_comment/${item.id}/`;
    return session.put(url, item).then(response => response.data);
  }

  deleteGeneralPollQuestionComment(id) {
    const url = `/general_poll_question_comment/${id}`;
    return session.delete(url, {}).then(response => response.data);
  }

  createGeneralPollQuestionCommentAttachment(item) {
    const url = `/general_poll_question_comment_attachment/`;
    return session.post(url, item).then(response => response.data);
  }

  updateGeneralPollQuestionCommentAttachment(item) {
    const url = `/general_poll_question_comment_attachment/${item.id}/`;
    return session.put(url, item).then(response => response.data);
  }

  async createGeneralPollQuestionCommentAttachmentFormData(item) {
    const url = `/general_poll_question_comment_attachment/`;
    return session.post(url, item).then(response => response.data);
  }

  updateGeneralPollQuestionCommentAttachmentFormData(id, item) {
    const url = `/general_poll_question_comment_attachment/${id}/`;
    return session.put(url, item).then(response => response.data);
  }

  deleteGeneralPollQuestionCommentAttachment(id) {
    const url = `/general_poll_question_comment_attachment/${id}`;
    return session.delete(url, {}).then(response => response.data);
  }


  voteUpGeneralPollQuestionComment(id) {
    const url = `/general_poll_question_comment/${id}/vote_up/`;

    return session.post(url, {}).then(response => response.data);
  }


  voteDownGeneralPollQuestionComment(id) {
    const url = `/general_poll_question_comment/${id}/vote_down/`;

    return session.post(url, {}).then(response => response.data);
  }


  referendumQuestionCommentRead(id) {
    const url = `/referendum_question_comment/${id}/read/`;

    return session.post(url, {}).then(response => response.data);
  }

  createReferendumQuestionComment(item) {
    const url = `/referendum_question_comment/`;
    return session.post(url, item).then(response => response.data);
  }

  updateReferendumQuestionComment(item) {
    const url = `/referendum_question_comment/${item.id}/`;
    return session.put(url, item).then(response => response.data);
  }


  deleteReferendumQuestionComment(id) {
    const url = `/referendum_question_comment/${id}`;
    return session.delete(url, {}).then(response => response.data);
  }

  referendumQuestionCommentVoteUp(id) {
    const url = `/referendum_question_comment/${id}/vote_up/`;

    return session.post(url, {}).then(response => response.data);
  }


  referendumQuestionCommentVoteDown(id) {
    const url = `/referendum_question_comment/${id}/vote_down/`;

    return session.post(url, {}).then(response => response.data);
  }

  createReferendumQuestionCommentAttachment(item) {
    const url = `/referendum_question_comment_attachment/`;
    return session.post(url, item).then(response => response.data);
  }

  updateReferendumQuestionCommentAttachment(item) {
    const url = `/referendum_question_comment_attachment/${item.id}/`;
    return session.put(url, item).then(response => response.data);
  }

  async createReferendumQuestionCommentAttachmentFormData(item) {
    const url = `/referendum_question_comment_attachment/`;
    return session.post(url, item).then(response => response.data);
  }

  updateReferendumQuestionCommentAttachmentFormData(id, item) {
    const url = `/referendum_question_comment_attachment/${id}/`;
    return session.put(url, item).then(response => response.data);
  }

  deleteReferendumQuestionCommentAttachment(id) {
    const url = `/referendum_question_comment_attachment/${id}`;
    return session.delete(url, {}).then(response => response.data);
  }

  // Survey Party Comment

  surveyPartyCommentRead(id) {
    const url = `/survey_party_comment/${id}/read/`;

    return session.post(url, {}).then(response => response.data);
  }

  createSurveyPartyComment(item) {
    const url = `/survey_party_comment/`;
    return session.post(url, item).then(response => response.data);
  }

  updateSurveyPartyComment(item) {
    const url = `/survey_party_comment/${item.id}/`;
    return session.put(url, item).then(response => response.data);
  }

  deleteSurveyPartyComment(id) {
    const url = `/survey_party_comment/${id}`;
    return session.delete(url, {}).then(response => response.data);
  }

  createSurveyPartyCommentAttachment(item) {
    const url = `/survey_party_comment_attachment/`;
    return session.post(url, item).then(response => response.data);
  }

  updateSurveyPartyCommentAttachment(item) {
    const url = `/survey_party_comment_attachment/${item.id}/`;
    return session.put(url, item).then(response => response.data);
  }

  async createSurveyPartyCommentAttachmentFormData(item) {
    const url = `/survey_party_comment_attachment/`;
    return session.post(url, item).then(response => response.data);
  }

  updateSurveyPartyCommentAttachmentFormData(id, item) {
    const url = `/survey_party_comment_attachment/${id}/`;
    return session.put(url, item).then(response => response.data);
  }

  deleteSurveyPartyCommentAttachment(id) {
    const url = `/survey_party_comment_attachment/${id}`;
    return session.delete(url, {}).then(response => response.data);
  }


  voteUpSurveyPartyComment(id) {
    const url = `/survey_party_comment/${id}/vote_up/`;

    return session.post(url, {}).then(response => response.data);
  }

  voteDownSurveyPartyComment(id) {
    const url = `/survey_party_comment/${id}/vote_down/`;

    return session.post(url, {}).then(response => response.data);
  }

  getSurveyPartyComments(sort, search, limit, offset, filter) {
    let url = "";

    if (filter) {
      var filter_url = filter.reduce(this.buildFilterUrl, "");

      url = `/survey_party_comment/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}${filter_url}`;
    }
    else
      url = `/survey_party_comment/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}`;

    return session.get(url, {}).then(response => response.data);
  }



  // END



  getReferendumQuestions(sort, search, limit, offset, filter) {
    let url = "";

    if (filter) {
      var filter_url = filter.reduce(this.buildFilterUrl, "");

      url = `/referendum_question/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}${filter_url}`;
    }
    else
      url = `/referendum_question/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}`;

    return session.get(url, {}).then(response => response.data);
  }

  getReferendumQuestion(id) {
    const url = `/referendum_question/${id}/`;
    return session.get(url, {}).then(response => response.data);
  }

  voteReferendumQuestion(type, id) {
    const url = `/referendum_question/${id}/vote/`;
    return session.post(url, { "type": type }).then(response => response.data);
  }

  unvoteReferendumQuestion(id) {
    const url = `/referendum_question/${id}/unvote/`;
    return session.post(url, {}).then(response => response.data);
  }

  likeReferendumQuestion(id) {
    const url = `/referendum_question/${id}/like/`;
    return session.post(url, {}).then(response => response.data);
  }

  unlikeReferendumQuestion(id) {
    const url = `/referendum_question/${id}/unlike/`;
    return session.post(url, {}).then(response => response.data);
  }

  addFavoriteReferendumQuestion(id) {
    const url = `/referendum_question/${id}/add_favorite/`;
    return session.post(url, {}).then(response => response.data);
  }

  removeFavoriteReferendumQuestion(id) {
    const url = `/referendum_question/${id}/remove_favorite/`;
    return session.post(url, {}).then(response => response.data);
  }


  getReferendums(sort, search, limit, offset, filter) {
    let url = "";

    if (filter) {
      var filter_url = filter.reduce(this.buildFilterUrl, "");

      url = `/referendum/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}${filter_url}`;
    }
    else
      url = `/referendum/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}`;

    return session.get(url, {}).then(response => response.data);
  }

  getReferendum(id) {
    const url = `/referendum_all/${id}/`;
    return session.get(url, {}).then(response => response.data);
  }

  getSurvey(id) {
    const url = `/survey/${id}/`;
    return session.get(url, {}).then(response => response.data);
  }


  getSurveys(sort, search, limit, offset, filter) {
    let url = "";

    if (filter) {
      var filter_url = filter.reduce(this.buildFilterUrl, "");

      url = `/survey/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}${filter_url}`;
    }
    else
      url = `/survey/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}`;

    return session.get(url, {}).then(response => response.data);
  }

  getSurveysAdmin(sort, search, limit, offset, filter) {
    let url = "";

    if (filter) {
      var filter_url = filter.reduce(this.buildFilterUrl, "");

      url = `/admin_survey/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}${filter_url}`;
    }
    else
      url = `/admin_survey/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}`;

    return session.get(url, {}).then(response => response.data);
  }


  getSurveyPartiesAdmin(sort, search, limit, offset, filter) {
    let url = "";

    if (filter) {
      var filter_url = filter.reduce(this.buildFilterUrl, "");

      url = `/admin_survey_party/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}${filter_url}`;
    }
    else
      url = `/admin_survey_party/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}`;

    return session.get(url, {}).then(response => response.data);
  }

  voteSurveyParty(id, survey_id) {
    const url = `/survey_party/${id}/vote/`;
    return session.post(url, { "survey": survey_id }).then(response => response.data);
    // return session.post(url, {}).then(response => response.data);
  }

  unvoteSurveyParty(id, survey_id) {
    const url = `/survey_party/${id}/unvote/`;
    return session.post(url, { "survey": survey_id }).then(response => response.data);
  }


  getReferendumsSimple() {
    let url = `/referendum/`;

    return session.get(url, {}).then(response => response.data);
  }


  getQuestionChooseSimple() {
    let url = `/question_choose/`;

    return session.get(url, {}).then(response => response.data);
  }


  createReferendumAdmin(item) {
    const url = `/admin_referendum/`;
    return session.post(url, item).then(response => response.data);
  }

  updateReferendumAdmin(item, id) {
    const url = `/admin_referendum/${id}/`;
    return session.put(url, item).then(response => response.data);
  }

  getReferendumsAdmin(sort, search, limit, offset, filter) {
    let url = "";

    if (filter) {
      var filter_url = filter.reduce(this.buildFilterUrl, "");

      url = `/admin_referendum/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}${filter_url}`;
    }
    else
      url = `/admin_referendum/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}`;

    return session.get(url, {}).then(response => response.data);
  }

  getReferendumAdmin(id) {
    const url = `/admin_referendum/${id}/`;
    return session.get(url, {}).then(response => response.data);
  }


  createQuestionChooseAdmin(item) {
    const url = `/admin_question_choose/`;
    return session.post(url, item).then(response => response.data);
  }

  updateQuestionChooseAdmin(item, id) {
    const url = `/admin_question_choose/${id}/`;
    return session.put(url, item).then(response => response.data);
  }

  getQuestionChoosesAdmin(sort, search, limit, offset, filter) {
    let url = "";

    if (filter) {
      var filter_url = filter.reduce(this.buildFilterUrl, "");

      url = `/admin_question_choose/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}${filter_url}`;
    }
    else
      url = `/admin_question_choose/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}`;

    return session.get(url, {}).then(response => response.data);
  }

  getQuestionChooseAdmin(id) {
    const url = `/admin_question_choose/${id}/`;
    return session.get(url, {}).then(response => response.data);
  }


  createReferendumQuestionAdmin(item) {
    const url = `/admin_referendum_question/`;
    return session.post(url, item).then(response => response.data);
  }

  updateReferendumQuestionAdmin(item, id) {
    const url = `/admin_referendum_question/${id}/`;
    return session.put(url, item).then(response => response.data);
  }

  patchReferendumQuestionAdmin(item, id) {
    const url = `/admin_referendum_question/${id}/`;
    return session.patch(url, item).then(response => response.data);
  }


  getReferendumQuestionsAdmin(sort, search, limit, offset, filter) {
    let url = "";

    if (filter) {
      var filter_url = filter.reduce(this.buildFilterUrl, "");

      url = `/admin_referendum_question/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}${filter_url}`;
    }
    else
      url = `/admin_referendum_question/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}`;

    return session.get(url, {}).then(response => response.data);
  }

  getReferendumQuestionAdmin(id) {
    const url = `/admin_referendum_question/${id}/`;
    return session.get(url, {}).then(response => response.data);
  }

  getReferendumArchive(sort, search, limit, offset, filter) {
    let url = "";

    if (filter) {
      var filter_url = filter.reduce(this.buildFilterUrl, "");

      url = `/referendum_archive/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}${filter_url}`;
    }
    else
      url = `/referendum_archive/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}`;

    return session.get(url, {}).then(response => response.data);
  }

  getQuestionChooseArchive(sort, search, limit, offset, filter) {
    let url = "";

    if (filter) {
      var filter_url = filter.reduce(this.buildFilterUrl, "");

      url = `/question_choose_archive/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}${filter_url}`;
    }
    else
      url = `/question_choose_archive/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}`;

    return session.get(url, {}).then(response => response.data);
  }

  getReferendumQuestionMy(sort, search, limit, offset, filter) {
    let url = "";

    if (filter) {
      var filter_url = filter.reduce(this.buildFilterUrl, "");

      url = `/referendum_question_my/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}${filter_url}`;
    }
    else
      url = `/referendum_question_my/?search=${search}&ordering=${sort}&limit=${limit}&offset=${offset}`;

    return session.get(url, {}).then(response => response.data);
  }

  getSurveyParty(id) {
    const url = `/survey_party/${id}/`;
    return session.get(url, {}).then(response => response.data);
  }

  updateSurveyParty(item) {
    const url = `/admin_survey_party/${item.id}/`;
    return session.put(url, item).then(response => response.data);
  }

  createSurveyParty() {
    const url = `/admin_survey_party/`;
    return session.post(url, {}).then(response => response.data);
  }

  createSurvey() {
    const url = `/admin_survey/`;
    return session.post(url, {}).then(response => response.data);
  }

  updateSurvey(item) {
    const url = `/admin_survey/${item.id}/`;
    return session.put(url, item).then(response => response.data);
  }

  addPartyToSurvey(survey_id, party_id) {
    const url = `/admin_survey/${survey_id}/add_party/`;
    return session.post(url, { "party_id": party_id }).then(response => response.data);
  }

  removePartyFromSurvey(survey_id, party_id) {
    const url = `/admin_survey/${survey_id}/remove_party/`;
    return session.post(url, { "party_id": party_id }).then(response => response.data);
  }

  addAdminGeneralPollCategory(poll_category_id, user_id) {
    const url = `/general_poll_category_admin/${poll_category_id}/add_admin/`;
    return session.post(url, { "user_id": user_id }).then(response => response.data);
  }

  removeAdminGeneralPollCategory(poll_category_id, user_id) {
    const url = `/general_poll_category_admin/${poll_category_id}/remove_admin/`;
    return session.post(url, { "user_id": user_id }).then(response => response.data);
  }

  addAdminGeneralPoll(poll_id, user_id) {
    const url = `/general_poll_admin/${poll_id}/add_admin/`;
    return session.post(url, { "user_id": user_id }).then(response => response.data);
  }

  removeAdminGeneralPoll(poll_id, user_id) {
    const url = `/general_poll_admin/${poll_id}/remove_admin/`;
    return session.post(url, { "user_id": user_id }).then(response => response.data);
  }

  resetPassword(email) {
    const url = `/user/password_reset_init/`;
    return session.post(url, { "email": email }).then(response => response.data);
  }

  resetPasswordFinal(email, code) {
    const url = `/user/password_reset_final/`;
    return session.get(url, { params: { "email": email, "code": code } }).then(response => response.data);
  }

  getHomeTiles() {
    const url = `/tiles/`;
    return session.get(url, {}).then(response => response.data);
  }

  createHomeTile(item) {
    const url = `/home_tile/`;
    return session.post(url, item).then(response => response.data);
  }

  updateHomeTile(item) {
    const url = `/home_tile/${item.id}/`;
    return session.put(url, item).then(response => response.data);
  }

  deleteHomeTile(id) {
    const url = `/home_tile/${id}`;
    return session.delete(url).then(response => response.data);
  }

  downloadItem(url, name) {
    session
      .get(url, { responseType: "blob" })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
      })
  }
}