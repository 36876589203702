import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import moment from 'moment'
import VueGtag from "vue-gtag";

import 'vuetify/dist/vuetify.min.css'

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  beforeCreate() {
    this.$store.dispatch('dataTable/loadStateFromStorage');
  },
  moment,
  render: h => h(App)
}).$mount('#app')

// PROD
Vue.use(VueGtag, {
  config: { id: "G-VRY0N7X7HX" }
}, router);

// TEST
// Vue.use(VueGtag, {
//   config: { id: "G-NETNFCKFQ8" }
// }, router);